import {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  PropsWithChildren,
} from 'react';

export enum ModalType {
  SELF_SERVE = "SELF_SERVE",
  PAGE_NOT_AVAILABLE_IN_PROVINCE = "PAGE_NOT_AVAILABLE_IN_PROVINCE",
  PCO_CARD_NUMBER_INFO = "PCO_CARD_NUMBER_INFO",
}

export const stringToModalType = (type: string): ModalType | null => {
  const lowerCaseType = type.toLowerCase();
  return Object.values(ModalType).find((t) => t.toLowerCase() === lowerCaseType) ?? null;
};

export interface ModalData{
  id: string
  modalType: ModalType;
  modalCTA:{
    buttonType: string;
    buttonLabel: string;
  }
  modalContentSections: []
}

export const removeModal = (
  modals: ModalType[],
  toRemove: ModalType,
): ModalType[] => modals.filter(modal => modal !== toRemove);

interface ModalContext {
  activeModals: ModalType[];
  openModal: (type: ModalType) => void;
  closeModal: (type: ModalType) => void;
}

const ModalContext = createContext<ModalContext | undefined>(undefined);

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

export const ModalProvider = ({ children }: PropsWithChildren) => {
  const [activeModals, setActiveModals] = useState<ModalType[]>([]);

  const openModal = useCallback((type: ModalType) => {
    setActiveModals(prevTypes => [type, ...removeModal(prevTypes, type)]);
  }, []);

  const closeModal = useCallback((type: ModalType) => {
    setActiveModals(prevTypes => removeModal(prevTypes, type));
  }, []);

  const value = useMemo(() => ({
    activeModals,
    openModal,
    closeModal,
  }), [activeModals, openModal, closeModal]);

  return (
    <ModalContext.Provider value={value}>
      {children}
    </ModalContext.Provider>
  );
};
